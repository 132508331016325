import './App.scss';
import Header from "./components/common/header";
import Footer from "./components/common/footer";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./components/Home";
import Contacto from "./components/Contacto";
import PageNotFound from "./components/PageNotFound";
import { CookieBanner } from '@palmabit/react-cookie-law';

import { languageText } from "./components/common/content.js";

let language = localStorage.getItem('language');

let cookiesMessage =  `${languageText(language).cookies.emoji}  ${languageText(language).cookies.texto}`

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/contacto" component={Contacto}/>
            <Route component={PageNotFound}/>
        </Switch>
      </Router>
      <Footer />
      <CookieBanner
        message= {cookiesMessage}
        acceptButtonText= {languageText(language).cookies.aceptar}
        declineButtonText= {languageText(language).cookies.refuse}
        managePreferencesButtonText= {languageText(language).cookies.manage}
        savePreferencesButtonText= {languageText(language).cookies.saveClose}
        wholeDomain={true}
        onAccept = {() => {}}
        onAcceptPreferences = {() => {}}
        onAcceptStatistics = {() => {}}
        onAcceptMarketing = {() => {}}
        styles={{
          dialog: { bottom: '20px', position: 'fixed', width: 'auto', maxWidth: 'calc(100% - 20px)', left: '20px', background: 'rgb(255 255 255)', padding: '20px', borderRadius: '10px', boxShadow: '0 5px 15px #0000004f' }
        }}
      />
    </div>
  );
}

export default App;
