import React from "react";
import { slide as Menu } from "react-burger-menu";
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import SelectorLanguage from './selectLanguage.jsx';
import MediaQuery from 'react-responsive';

import { languageText } from "./content.js";

let language = localStorage.getItem('language');


class Burger extends React.Component{
    
    constructor (props) {
        super(props)
        this.state = {
            menuOpen: false
        }
    }
    
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }
    
    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu () {
        this.setState({menuOpen: false})
    }
    
    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }

    render () {
        return (
          <div>
            <Menu right
              isOpen={this.state.menuOpen}
              onStateChange={(state) => this.handleStateChange(state)}
            >
            <ul>
                <li><HashLink to="/#" onClick={() => this.closeMenu()} >{languageText(language).nav.navInicio}</HashLink></li>
                <li><HashLink to="/#service" onClick={() => this.closeMenu()} >{languageText(language).nav.navServicios}</HashLink></li>
                <li><HashLink to="/#about-us" onClick={() => this.closeMenu()} >{languageText(language).nav.navNosotros}</HashLink></li>
                <li><Link to="/contacto" onClick={() => this.closeMenu()} >{languageText(language).nav.navContacto}</Link></li>
            </ul>  
            <MediaQuery maxWidth={750}>
              <SelectorLanguage />
            </MediaQuery>
            </Menu>
          </div>
        )
      }
    }

    export default Burger;