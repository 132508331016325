import { languageText } from "./content.js";

let language;

let localLanguage = localStorage.getItem('language');

if (localLanguage == null) {
    localStorage.setItem('language', 'CAT');
}
if (language !== localLanguage) {
    language = localLanguage;
}
    


function setLangStorage() { 
    let newLang = document.querySelector('#language-selector').value;
    localStorage.setItem('language', newLang);
    language = newLang;
    console.log('new language', language)
    window.location.reload();
};

console.log(languageText(language));

function SelectorLanguage() {
return(
    <select id="language-selector" value={language} onChange={setLangStorage} >
        <option value="CAT">Català</option>
        <option value="ES">Español</option>
    </select>
);

}

export default SelectorLanguage;