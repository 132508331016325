export function languageText(idioma) {
   
    let text;
 
    switch (idioma) {
    case 'ES': 
        text = {
            nav : {
                navInicio : 'Inicio', 
                navServicios : 'Servicios', 
                navNosotros : 'Nosotros', 
                navContacto : 'Contacto' 
             },

             slides : { //1
                first : 'Sus proyectos en las mejores manos',
                second : 'Corte láser'
             },

             cookiesConfig : { //2
                cookiesHeading : '🍪 Mi titulo',
                cookiesDescription : 'We use cookies to offer a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. Please review our <a href="/privacy-policy">privacy policy page</a>. By clicking accept, you consent to our privacy policy & use of cookies.',
                cookiesAcceptLabel : 'Acceptar cookies',
                cookiesSettingsLabel : 'Ver preferences',
                cookiesCloseLabel : 'Close window'
             },
             
             home : { //3
                hTitle : 'SUS PROYECTOS, EN LAS MEJORES MANOS',
                hDesc : `Gran parte de nuestro trabajo consiste en entender las necesidades y las prioridades de cada cliente; le escuchamos y también le proponemos soluciones para desarrollar sus proyectos. 
                         Para ello, contamos con un equipo técnico y de producción, con más de 30 años de experiencia en el sector, y con la maquinaria tecnológicamente más avanzada`
             },

             services : { //4
                sTitle : 'Con nosotros, sus proyectos estarán en las mejores manos.',
                sDesc : `Disponemos de la maquinaria más avanzada junto con un cualificado equipo de profesionales del mecanizado y del montaje, ambos en permanente proceso de formación, para poder ofrecer a nuestros clientes la mejor solución a sus necesidades.
                         Nos tomamos la interacción con el cliente muy en serio para que los resultados sean los esperados.
                         En esta línea, les ofrecemos los siguientes servicios:`,
                serviceOneTitle : 'CENTRO DE CORTE LÁSER / AGUA',
                serviceOneDesc : 'Centro de corte por láser de fibra doble de mesa HS-G3015A, medidas mesa 3000x1500',
                serviceSecondTitle : 'CENTRO DE MECANIZADOS',
                serviceSecondDesc : 'Dos centros Quaser MV154P; recorridos de la mesa: 700 x 500 x 500. Centro HardFord VMC-850; recorridos de la mesa: 850 x 500 x 500.',
                serviceThirdTitle : 'CENTRO DE TORNEADOS',
                serviceThirdDesc : 'Centro Pinacho Cobra CNC-180; recorridos: ø180 x 1.000.',
                serviceFourTitle : 'MECANIZADO CONVENCIONAL',
                serviceFourDesc : 'Fresadora, torno, taladros de columna, entalladora, sierra automática, marcado de micropunto.',
                serviceFifthTitle : 'PLANCHISTERÍA Y CALDERERÍA',
                serviceFifthDesc : 'Cizalla, plegadora, soldadura de hilo, soldadura TIG continua.'
             },
             
             aboutUs : { //5
                aTitle : 'SOBRE NOSOTROS',
                aDesc : `Desde 1982, en Mecatall300 vamos un paso por delante ofreciendo la máxima calidad en cada proyecto. 
                         Para ello, contamos con grandes profesionales que trabajan día a día para mejorar los procesos de fabricación y la calidad de nuestro servicio. Más de 30 años de experiencia en el sector nos avalan.
                         En Mecatall300 estamos comprometidos con el trabajo bien hecho. Por eso, nos encanta decir que nuestra mejor carta de presentación es la satisfacción y la confianza de los clientes con los que colaboramos. Este es, sin duda, nuestro mejor logro.
                         Sus proyectos, en las mejores manos.`
             },

             footer : {
               titleLocation: 'Visítenos',
               street: 'Carrer de Girona, 83',
               location: '08203 Sabadell (Barcelona)',
               titleContact: 'Contacta',
               mail: 'taller@mecatall300.com',
               phone : '93 720 52 92',
               menu : 'Menú'
             },

             contact : {
                title : 'Contacto',
                subTitle : 'Dónde estamos',
                secondTitle : 'Contáctanos'
             },

             cookies: {
                emoji: '🍪',
                texto: 'Utilizamos cookies propias y de terceros para mejorar la experiencia del usuario a través de su navegación. Si continúas navegando aceptas su uso.',
                aceptar: 'Aceptar',
                refuse: 'Rechazar',
                manage: 'Gestionar cookies',
                saveClose: 'Guardar y cerrar'
             }
        };
  
       return(text);
    
    case 'CAT': 
        text = {
            nav : { //0
                navInicio : 'Inici', 
                navServicios : 'Serveis', 
                navNosotros : 'Nosaltres', 
                navContacto : 'Contacte' 
             },
       
             slides : { //1
               first : 'Els vostres projectes a les millors mans',
               second : 'Tall Làser'
            },
       
             cookiesConfig : { //2
                cookiesHeading : '🍪 Mi titulo',
                cookiesDescription : 'We use cookies to offer a better browsing experience, analyze site traffic, personalize content, and serve targeted advertisements. Please review our <a href="/privacy-policy">privacy policy page</a>. By clicking accept, you consent to our privacy policy & use of cookies.',
                cookiesAcceptLabel : 'Acceptar cookies',
                cookiesSettingsLabel : 'Ver preferences',
                cookiesCloseLabel : 'Close window'
             },
       
             home : { //3
                hTitle : 'ELS SEUS PROJECTES, A LES MILLORS MANS',
                hDesc : `Gran part del nostre treball consisteix a entendre les necessitats i les prioritats de cada client; l'escoltem i també li proposem solucions per desenvolupar els seus projectes.
                         Per això, comptem amb un equip tècnic i de producció, amb més de 30 anys d'experiència en el sector i amb la maquinària amb la més avançada tecnologia.`
             },
            
             services : { //4
                sTitle : `Amb nosaltres, els seus projectes estaran a les millors mans.`,
                sDesc : `Disposem de la maquinària més avançada juntament amb un qualificat equip de professionals de la mecanització i del muntatge, tots dos en permanent procés de formació, per poder oferir als nostres clients la millor solució per a les seves necessitats.
                        Tractem la interacció amb el client molt seriosament perquè els resultats siguin els esperats.
                        En aquesta línia, els oferim els serveis següents:`,
                serviceOneTitle : 'CENTRE DE TALL LÀSER / AIGUA',
                serviceOneDesc : 'Centre de tall per làser de fibra, doble de taula HS-G3015A; mesures de la taula: 3.000 x 1.500.',
                serviceSecondTitle : 'CENTRE DE MECANITZATS',
                serviceSecondDesc : 'Dos centres Quaser MV154P; recorreguts de la taula: 700 x 500 x 500. Centre Hardford VMC-850; recorreguts de la taula: 850 x 500 x 500.',
                serviceThirdTitle : 'CENTRE DE TORNEJATS',
                serviceThirdDesc : 'Centre Pinacho Cobra CNC-180; recorreguts: ø180 x 1.000.',
                serviceFourTitle : 'MECANITZAT CONVENCIONAL',
                serviceFourDesc : 'Fresadora, torn, trepants de columna, entalladora, serra automàtica, marcatge de micropunt.',
                serviceFifthTitle : 'PLANXISTERIA I CALDERERIA',
                serviceFifthDesc : 'Cisalla, plegadora, soldadura de fil, soldadura TIG contínua.'
             },
            
             aboutUs : { //5
                aTitle : `SOBRE NOSALTRES`,
                aDesc : `Des de 1982, a Mecatall300 anem un pas per davant amb la màxima qualitat en cada projecte.
                         Per això, comptem amb grans professionals que treballen dia a dia per millorar els processos de fabricació i la qualitat del nostre servei. Més de 30 anys d'experiència en el sector ens avalen.
                         A Mecatall300 estem compromesos amb la feina ben feta. Per això, ens agrada dir que la nostra millor carta de presentació és la satisfacció i la confiança dels clients amb els quals col·laborem. Aquest és, sens dubte, el nostre millor èxit.
                         Els seus projectes, a les millors mans.`
             },
       
             footer : {
               titleLocation: 'Visiti’ns',
               street: 'Carrer de Girona, 83',
               location: '08203 Sabadell (Barcelona)',
               titleContact: 'Contacta',
               mail: 'taller@mecatall300.com',
               phone : '93 720 52 92',
               menu : 'Menú'
             },

             contact : {
               title : 'Contacte',
               subTitle : 'On som',
               secondTitle : 'Contacte'
            },

            cookies: {
               emoji: '🍪',
               texto: `Utilitzem cookies pròpies i de tercers per a millorar l'experiència de l'usuari a través de la seva navegació. Si continues navegant acceptes el seu ús.`,
               aceptar: 'Acceptar',
               refuse: 'Rebutjar',
               manage: 'Gestionar cookies',
               saveClose: 'Guardar i tancar'
            }
        };
       
       return(text);
 
    default: 
       return "Somthing wrong";
    }
 };
 