import React from "react"
import { languageText } from "./common/content.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

let language = localStorage.getItem('language');

const Contacto = () => (
  <>
  <div className="content">
    <div className="contact">
      <div className="container">
        <div className="textos">
          <h1>{languageText(language).contact.title}</h1>
          <h2>{languageText(language).contact.subTitle}</h2>
          <p>{languageText(language).footer.street}</p>
          <h2>{languageText(language).contact.secondTitle}</h2>
          <ul>
              <li><FontAwesomeIcon icon={faEnvelope} /> {languageText(language).footer.mail}</li>
              <li><FontAwesomeIcon icon={faPhone} /> {languageText(language).footer.phone}</li>
          </ul>
        </div>
        <div className="map">
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2986.5672959334!2d2.106379215430352!3d41.535312679250794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a495ac5bcf8461%3A0x22f8433e7641135c!2sCarrer%20de%20Girona%2C%2083%2C%2008203%20Sabadell%2C%20Barcelona!5e0!3m2!1ses!2ses!4v1616868501747!5m2!1ses!2ses"></iframe>
        </div>
      </div>
    </div>
  </div>
  </>
)
export default Contacto