import React from "react";
import { languageText } from "./common/content.js";

let language = localStorage.getItem('language');


const Home = () => (
  <>
    <div className="content">
      <section className="proyectos">
          <div className="banner">
            <div className="container">
              <div className="claim">{languageText(language).slides.first}</div>
            </div>
          </div>
          <div className="container">
              <div className="title-logo">Mecatall<span>300</span></div>
              <div className="title">{languageText(language).home.hTitle}</div>
              <div className="description">{languageText(language).home.hDesc}</div>
          </div>
      </section>
      
      <section className="servicios" id="service">
          <div className="banner">
            <div className="container">
            <div className="claim">{languageText(language).slides.second}</div>
            </div>
          </div>
          <div className="container">
              <div className="title-logo">Mecatall<span>300</span></div>
              <div className="title">{languageText(language).services.sTitle}</div>
              <div className="description">{languageText(language).services.sDesc}</div>
              <div className="lista-servicios">
                  <ul>
                      <li>
                          <div className="imagen">
                              <img src="static/assets/corte_aigua.jpeg" alt="Logo Mecatall 3000"/>
                          </div>
                          <div className="title">{languageText(language).services.serviceOneTitle}</div>
                          <div className="desc">{languageText(language).services.serviceOneDesc}</div>
                      </li>
                      <li>
                          <div className="imagen">
                              <img src="static/assets/CNC3.jpg" alt="Logo Mecatall 3000"/>
                          </div>
                          <div className="title">{languageText(language).services.serviceSecondTitle}</div>
                          <div className="desc">{languageText(language).services.serviceSecondDesc}</div>
                      </li>
                      <li>
                          <div className="imagen">
                              <img src="static/assets/operator-machining-mold.jpg" alt="Logo Mecatall 3000"/>
                          </div>
                          <div className="title">{languageText(language).services.serviceThirdTitle}</div>
                          <div className="desc">{languageText(language).services.serviceThirdDesc}</div>
                      </li>
                      <li>
                          <div className="imagen">
                              <img src="static/assets/fresadora.jpg" alt="Logo Mecatall 3000"/>
                          </div>
                          <div className="title">{languageText(language).services.serviceFourTitle}</div>
                          <div className="desc">{languageText(language).services.serviceFourDesc}</div>
                      </li>
                      <li>
                          <div className="imagen">
                              <img src="static/assets/soldadura.jpg" alt=""/>
                          </div>
                          <div className="title">{languageText(language).services.serviceFifthTitle}</div>
                          <div className="desc">{languageText(language).services.serviceFifthDesc}</div>
                      </li>
                  </ul>
              </div>
          </div>
      </section>
      
      <section className="nosotros" id="about-us" >
          <div className="banner">
          </div>
          <div className="container">
              <div className="title-logo">Mecatall<span>300</span></div>
              <div className="title">{languageText(language).aboutUs.aTitle}</div>
              <div className="description">{languageText(language).aboutUs.aDesc}</div>
          </div>
      </section>
    </div>
  </>
)
export default Home