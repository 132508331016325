import logo from '../../assets/logo.png';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { languageText } from "./content.js";
import Burger from './burger.jsx';
import SelectorLanguage from './selectLanguage.jsx';
import MediaQuery from 'react-responsive';

let language = localStorage.getItem('language');

console.log(languageText(language));

function Header() {
    return (
        <header>
            <div className="container">
                <Link to="/">
                <div className="logo">
                    <img src={logo} alt="Logo Mecatall 3000" />
                </div>
                </Link>
                <div className="menus">
                    <MediaQuery minWidth={751}>
                        <SelectorLanguage />
                    </MediaQuery>
                    <nav>
                        <ul>
                            <li><HashLink to="/#" >{languageText(language).nav.navInicio}</HashLink></li>
                            <li><HashLink to="/#service" >{languageText(language).nav.navServicios}</HashLink></li>
                            <li><HashLink to="/#about-us" >{languageText(language).nav.navNosotros}</HashLink></li>
                            <li><Link to="/contacto" >{languageText(language).nav.navContacto}</Link></li>
                        </ul>  
                    </nav>
                </div>
                <div className="burger">
                    <Burger pageWrapId={"page-wrap"} outerContainerId={"App"} />  
                </div>
            </div>
        </header>
    );
}

export default Header;
