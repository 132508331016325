import logo from '../../assets/logo.png';
import { languageText } from "./content.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

let language = localStorage.getItem('language');

function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="column">
                    <img src={logo} alt="Logo Mecatall 3000" />
                </div>
                <div className="column">
                    <div className="title">
                        {languageText(language).footer.titleLocation}
                    </div>
                    <div className="desc">
                        <ul>
                            <li>{languageText(language).footer.street}</li>
                            <li>{languageText(language).footer.location}</li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className="title">
                        {languageText(language).footer.titleContact}
                    </div>
                    <div className="desc">
                        <ul>
                            <li><FontAwesomeIcon icon={faEnvelope} /> {languageText(language).footer.mail}</li>
                            <li><FontAwesomeIcon icon={faPhone} /> {languageText(language).footer.phone}</li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className="title">
                        {languageText(language).footer.menu}
                    </div>
                    <div className="desc">
                        <ul className="menu">
                            <li><a href="/#" >{languageText(language).nav.navInicio}</a></li>
                            <li><a href="/#service" >{languageText(language).nav.navServicios}</a></li>
                            <li><a href="/#about-us" >{languageText(language).nav.navNosotros}</a></li>
                            <li><a href="/contacto" >{languageText(language).nav.navContacto}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
